import { store } from "@/store/store";
import { datos_empresa } from "@/shared/dtos/datos_empresa";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "datos_empresaModule",
  store,
  dynamic: true,
})
class datos_empresaModule extends VuexModule {
  public datos_empresas: datos_empresa[] = [];
  public datos_empresa: datos_empresa = new datos_empresa();

  @Action({ commit: "onGetdatos_empresas" })
  public async getdatos_empresas() {
    return await ssmHttpService.get(API.datos_empresa);
  }

  @Action({ commit: "onGetdatos_empresa" })
  public async getdatos_empresa(id: any) {
    return await ssmHttpService.get(API.datos_empresa + "/" + id);
  }

  @Action({ commit: "onGetdatos_empresa" })
  public async getdatos_empresaAdmin(id: any) {
    return await ssmHttpService.get(API.datos_empresa + "/cliente/" + id);
  }

  @Action
  public async guardardatos_empresa(datos_empresa: datos_empresa) {
    return await ssmHttpService.post(API.datos_empresa, datos_empresa.toJson());
  }

  @Action
  public async modificardatos_empresa(datos_empresa: datos_empresa) {
    return await ssmHttpService.put(
      API.datos_empresa + "/" + datos_empresa.id,
      datos_empresa
    );
  }

  @Action
  public async guardardatos_empresa_admin(datos_empresa: datos_empresa) {
    return await ssmHttpService.post(API.datos_empresa + "/cliente/", datos_empresa.toJson());
  }

  @Action
  public async modificardatos_empresa_admin(datos_empresa: datos_empresa) {
    return await ssmHttpService.put(API.datos_empresa + "/cliente/", datos_empresa);
  }

  @Action
  public async eliminardatos_empresa(datos_empresa: datos_empresa) {
    return await ssmHttpService.delete(
      API.datos_empresa + "/" + datos_empresa.id,
      null,
      false
    );
  }

  @Mutation
  public onGetdatos_empresas(res: datos_empresa[]) {
    this.datos_empresas = res ? res.map((x) => new datos_empresa(x)) : [];
  }

  @Mutation
  public onGetdatos_empresa(res: datos_empresa) {
    this.datos_empresa = new datos_empresa(res);
  }
}
export default getModule(datos_empresaModule);
