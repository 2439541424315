import { store } from "@/store/store";
import { fichajes_empleado } from "@/shared/dtos/fichajes_empleado";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { fichajes_form_masive } from "@/shared/dtos/fichajes_form_masive";
import { ClassReportWizardFormFichajes } from "@/shared/dtos/ClassReportWizardFormFichajes";

@Module({
  namespaced: true,
  name: "fichajes_empleadoModule",
  store,
  dynamic: true,
})
class fichajes_empleadoModule extends VuexModule {
  public fichajes_empleados: fichajes_empleado[] = [];
  public fichajes_empleado: fichajes_empleado = new fichajes_empleado();

  @Action({ commit: "onGetfichajes_empleados" })
  public async getfichajes_empleados() {
    return await ssmHttpService.get(API.fichajes_empleado);
  }

  @Action({ commit: "onGetfichajes_empleados" })
  public async getfichajes_empleadosByIdUsuario(id: number) {
    return await ssmHttpService.get(API.fichajes_empleado + "/fichajes_empleado/" + id);
  }

  @Action({ commit: "onGetfichajes_empleado" })
  public async getfichajes_empleado(id: any) {
    return await ssmHttpService.get(API.fichajes_empleado + "/" + id);
  }
  @Action({})
  public async gettestfilefichajes_empleados() {
    return await ssmHttpService.get(API.fichajes_empleado + "/TestReport1/");
  }

  @Action
  public async guardarfichajes_empleado(fichajes_empleado: fichajes_empleado) {
    return await ssmHttpService.post(
      API.fichajes_empleado,
      fichajes_empleado.toJson()
    );
  }

  @Action
  public async guardarfichajes_masivos(
    fichajes_empleado_masivo: fichajes_form_masive
  ) {
    return await ssmHttpService.post(
      API.fichajes_empleado + "/MasiveCreate/",
      fichajes_empleado_masivo.toJson()
    );
  }

  @Action
  public async GenerarInformePorFecha(report: ClassReportWizardFormFichajes) {
    return await ssmHttpService.post(
      API.fichajes_empleado + "/Report1/",
      report.toJson()
    );
  }

  @Action
  public async GenerarInformePorFechahtml(report: ClassReportWizardFormFichajes) {
    return await ssmHttpService.post(
      API.fichajes_empleado + "/Report1/html",
      report.toJson()
    );
  }

  @Action
  public async GenerarInformePorEmpleado(
    report: ClassReportWizardFormFichajes
  ) {
    return await ssmHttpService.post(
      API.fichajes_empleado + "/Report2/",
      report.toJson()
    );
  }
  @Action
  public async GenerarInformePorCentroTrabajo(
    report: ClassReportWizardFormFichajes
  ) {
    return await ssmHttpService.post(
      API.fichajes_empleado + "/Report3/",
      report.toJson()
    );
  }
  @Action
  public async modificarfichajes_empleado(
    fichajes_empleado: fichajes_empleado
  ) {
    return await ssmHttpService.put(
      API.fichajes_empleado + "/" + fichajes_empleado.id,
      fichajes_empleado
    );
  }

  @Action
  public async eliminarfichajes_empleado(fichajes_empleado: fichajes_empleado) {
    return await ssmHttpService.delete(
      API.fichajes_empleado + "/" + fichajes_empleado.id,
      null,
      false
    );
  }

  @Mutation
  public onGetfichajes_empleados(res: fichajes_empleado[]) {
    this.fichajes_empleados = res
      ? res.map((x) => new fichajes_empleado(x))
      : [];
  }

  @Mutation
  public onGetfichajes_empleado(res: fichajes_empleado) {
    this.fichajes_empleado = new fichajes_empleado(res);
  }
}
export default getModule(fichajes_empleadoModule);
