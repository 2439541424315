import { BaseDto } from "@/shared/dtos/base-dto";
import { hist_contratos_empleado } from "./hist_contratos_empleado";

export class Usuario extends BaseDto {
  public email!: string;
  public contra!: string;
  public nombre!: string;
  public cambiar_pwd_entrar!: boolean;
  public id_rol!: number;
  public descripcion!: string;
  public nif!: string;
  public id_centro_trabajo!: number;
  public num_seg_social!: string;
  public hist_contratos_empleado: hist_contratos_empleado[] = [];
  public ids_datos_empresa: number[] = [];
}
