import { store } from '@/store/store';
import { tipos_fichaje } from '@/shared/dtos/tipos_fichaje';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'tipos_fichajeModule',
    store,
    dynamic: true
})
class tipos_fichajeModule extends VuexModule {
    public tipos_fichajes: tipos_fichaje[] = [];
    public tipos_fichaje: tipos_fichaje = new tipos_fichaje();

    @Action({ commit: 'onGettipos_fichajes' })
    public async gettipos_fichajes() {
        return await ssmHttpService.get(API.tipos_fichaje);
    }
    @Action({ commit: 'onGettipos_fichajes' })
    public async gettipos_fichajes_public() {
        return await ssmHttpService.get(API.public+'/Fichajes');
    }

    @Action({ commit: 'onGettipos_fichaje' })
    public async gettipos_fichaje(id: any) {
        return await ssmHttpService.get(API.tipos_fichaje + '/' + id);
    }

    @Action
    public async guardartipos_fichaje(tipos_fichaje: tipos_fichaje) {
       return await ssmHttpService.post(API.tipos_fichaje, tipos_fichaje.toJson());
    }

    @Action
    public async modificartipos_fichaje(tipos_fichaje: tipos_fichaje) {
        return await ssmHttpService.put(API.tipos_fichaje + '/' + tipos_fichaje.id, tipos_fichaje);
    }

    @Action
    public async eliminartipos_fichaje(tipos_fichaje: tipos_fichaje) {
       return await ssmHttpService.delete(API.tipos_fichaje + '/' + tipos_fichaje.id, null, false);
    }

    @Mutation
    public onGettipos_fichajes(res: tipos_fichaje[]) {
        this.tipos_fichajes = res ? res.map((x) => new tipos_fichaje(x)) : []
    }

    @Mutation
    public onGettipos_fichaje(res: tipos_fichaje) {
        this.tipos_fichaje = new tipos_fichaje(res);
    }

}
export default getModule(tipos_fichajeModule);